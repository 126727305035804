.table {
    & th img,
    & td img {
        max-height: 10vh;
    }
}
.cell-center {
    & th,
    & td {
        text-align: center;
        vertical-align: middle !important;
    }
}
.row-checker {
    width: 1.5rem;
}
