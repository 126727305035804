var OriginalPromise = Promise;

/**
 * ES5 subclassing is used per:
 * https://github.com/rtsao/browser-unhandled-rejection/issues/1
 * https://kangax.github.io/compat-table/es6/#test-Promise_is_subclassable
 *
 * Adapted from: https://gist.github.com/domenic/8ed6048b187ee8f2ec75
 */
var InstrumentedPromise$1 = function Promise(resolver) {
  if (!(this instanceof InstrumentedPromise$1)) {
    throw new TypeError('Cannot call a class as a function');
  }
  var promise = new OriginalPromise(function (resolve, reject) {
    return resolver(resolve, function (arg) {
      OriginalPromise.resolve().then(function () {
        if (promise._hasDownstreams === undefined) {
          dispatchUnhandledRejectionEvent(promise, arg);
        }
      });
      return reject(arg);
    });
  });
  promise.__proto__ = InstrumentedPromise$1.prototype;
  return promise;
};

InstrumentedPromise$1.__proto__ = OriginalPromise;
InstrumentedPromise$1.prototype.__proto__ = OriginalPromise.prototype;

InstrumentedPromise$1.prototype.then = function then(onFulfilled, onRejected) {
  var next = OriginalPromise.prototype.then.call(this, onFulfilled, onRejected);
  this._hasDownstreams = true;
  return next;
};

function dispatchUnhandledRejectionEvent(promise, reason) {
  var event = document.createEvent('Event');
  /**
   * Note: these properties should not be enumerable, which is the default setting
   */
  Object.defineProperties(event, {
    promise: {
      value: promise,
      writable: false
    },
    reason: {
      value: reason,
      writable: false
    }
  });
  event.initEvent('unhandledrejection', // Define that the event name is 'unhandledrejection'
  false, // PromiseRejectionEvent is not bubbleable
  true // PromiseRejectionEvent is cancelable
  );
  window.dispatchEvent(event);
}

function needsPolyfill() {
  return typeof PromiseRejectionEvent === 'undefined';
}

function polyfill() {
  Promise = InstrumentedPromise$1;
}

function auto() {
  if (needsPolyfill()) {
    polyfill();
  }
}

export { needsPolyfill, polyfill, auto };export default InstrumentedPromise$1;
