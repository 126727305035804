export interface User {
    name: string;
    position: string;
    summary: string;
    GitHub: string;
}

export const TechQuery: User = {
    name: '水歌',
    position: '创始人',
    summary:
        'Web/JavaScript 全栈工程师、WebCell 开源前端框架作者，微软 MVP、阿里云 MVP、FCC 全球百强志愿者，FCC 成都社区负责人、开源社理事。',
    GitHub: 'TechQuery'
};

export const lingziyb: User = {
    name: '灵儿',
    position: '联合创始人',
    summary:
        'Web/JavaScript 全栈工程师，汉梦文化汉服社区创始人，FCC 成都社区核心组织者。',
    GitHub: 'lingziyb'
};

export const too: User = {
    name: 'Too',
    position: '团队润滑剂/敏捷开发专员',
    summary:
        'Python 工程师，擅长使用精益创业和敏捷开发等技术方法来保证项目产品开发和交付，负责沟通协调团队成员，充分理解用户需求，交付优质产品。',
    GitHub: 'too'
};

export const demongodYY: User = {
    name: '书香墨剑',
    position: '开发者',
    summary: 'Web/JavaScript 全栈工程师，FCC 成都社区核心组织者。',
    GitHub: 'demongodYY'
};

export const Cute233: User = {
    name: '喵喵',
    position: '开发者',
    summary:
        'Web/JavaScript 前端工程师，开源社优秀志愿者、TEDxChengdu 志愿者，FCC 成都社区核心组织者。',
    GitHub: 'Cute233'
};

export const Akagilnc: User = {
    name: 'AK',
    position: 'PM/开发者',
    summary: 'Python后台工程师，FCC 成都社区核心组织者，摄影师。',
    GitHub: 'Akagilnc'
};

export const tree7ion: User = {
    name: '小树',
    position: '业务分析师/大数据产品经理',
    summary: '资深B端产品经理，产业互联网先行者，猫奴。',
    GitHub: 'tree7ion'
};

export const stevending1st: User = {
    name: '丁止戈',
    position: 'Web/JavaScript 开发者',
    summary: '前端打杂，fCC 志愿者，关注开放式协作。',
    GitHub: 'stevending1st'
};

export const mythcsj: User = {
    name: 'mythcsj',
    position: '社区之光',
    summary:
        'Java/Node.js 开发者，成都市万州联合商会理事、freeCodeCamp 成都核心组织者、华为云享专家、华为云 HCDG 成都负责人、华为成都昇腾 AI 开发者俱乐部主理人、阿里云飞天 Club 成都核心成员、极狐 GitLab 成都 User Group Leader、声网 Agora 成都俱乐部主理人、TiDB Community 成都地区负责人、CSDN Meetup 成都站长',
    GitHub: 'mythcsj'
};

export const manyuanrong: User = {
    name: 'EnokMan',
    position: 'Web/JavaScript 全栈工程师',
    summary: 'Deno 中文社区创始人、汉梦文化汉服社区联合创始人',
    GitHub: 'manyuanrong'
};

export default [
    TechQuery,
    lingziyb,
    too,
    demongodYY,
    Cute233,
    Akagilnc,
    tree7ion,
    stevending1st,
    mythcsj,
    manyuanrong
];
