.case {
    .preview {
        min-height: 10rem;
        background-position: top;
        background-size: 100%;
        transition: 0.25s;
    }
    &:hover .preview {
        background-size: 120%;
    }
}
