/**
 * @module ErrorCorrectionLevel
 * @author nuintun
 * @author Cosmo Wolfe
 * @author Kazuhiko Arase
 */
/**
 * @readonly
 */
var ErrorCorrectionLevel;
(function (ErrorCorrectionLevel) {
    // 7%
    ErrorCorrectionLevel[ErrorCorrectionLevel["L"] = 1] = "L";
    // 15%
    ErrorCorrectionLevel[ErrorCorrectionLevel["M"] = 0] = "M";
    // 25%
    ErrorCorrectionLevel[ErrorCorrectionLevel["Q"] = 3] = "Q";
    // 30%
    ErrorCorrectionLevel[ErrorCorrectionLevel["H"] = 2] = "H";
})(ErrorCorrectionLevel || (ErrorCorrectionLevel = {}));

export { ErrorCorrectionLevel };
