.spinner-box {
    position: relative;
    min-height: 3rem;
}
.spinner-cover {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1080;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.25s;

    &.active {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        opacity: 1;
    }
}
