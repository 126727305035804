/**
 * @module MaskPattern
 * @author nuintun
 * @author Cosmo Wolfe
 * @author Kazuhiko Arase
 */
function getMaskFunc(maskPattern) {
    switch (maskPattern) {
        case 0 /* PATTERN000 */:
            return function (x, y) { return ((x + y) & 0x1) === 0; };
        case 1 /* PATTERN001 */:
            return function (_x, y) { return (y & 0x1) === 0; };
        case 2 /* PATTERN010 */:
            return function (x, _y) { return x % 3 === 0; };
        case 3 /* PATTERN011 */:
            return function (x, y) { return (x + y) % 3 === 0; };
        case 4 /* PATTERN100 */:
            return function (x, y) { return ((((x / 3) >> 0) + ((y / 2) >> 0)) & 0x1) === 0; };
        case 5 /* PATTERN101 */:
            return function (x, y) { return ((x * y) & 0x1) + ((x * y) % 3) === 0; };
        case 6 /* PATTERN110 */:
            return function (x, y) { return ((((x * y) & 0x1) + ((x * y) % 3)) & 0x1) === 0; };
        case 7 /* PATTERN111 */:
            return function (x, y) { return ((((x * y) % 3) + ((x + y) & 0x1)) & 0x1) === 0; };
        default:
            throw new Error("illegal mask: ".concat(maskPattern));
    }
}

export { getMaskFunc };
