.fileBox {
    position: relative;
    width: 16rem;
    height: 10rem;
    border: 2px dashed lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
        content: '+';
        color: lightblue;
        font-size: 5rem;
        text-align: center;
        display: block;
        width: 5rem;
        height: 5rem;
        line-height: 0.8;
    }
    &.active {
        border-color: transparent;
        &::before {
            content: none;
        }
    }
}
